// Types
type EmailService = 'both' | 'helpscout' | 'plain';
type BeaconType = 'custom' | 'helpscout';

// Contact Form
export const DEFAULT_SUBJECT = 'Help Request';
export const MINIUM_MESSAGE_LENGTH = 10;
export const MAXIMUM_MESSAGE_LENGTH = 1000;

// Email
export const EMAIL_DESTINATION = 'support@imenupro.com';

// Configure which email service to use.
// If set to 'both', the app will send to both services - otherwise
// it will only send to the specified service.
//
// Options: 'both', 'helpscout', 'plain'
//
export const EMAIL_HELPDESK_SERVICE: EmailService = 'both';

// Configure which Beacon to use. This config is used easily switch between them.
//
// If set to 'custom', the app will use the custom beacon.
// If set to 'helpscout', the app will use the helpscout beacon.
//
// Options: 'custom', 'helpscout'
export const BEACON_TYPE: BeaconType = 'custom';
