<script lang="ts">
	import { actions, isInputError } from 'astro:actions';
	import { onMount } from 'svelte';
	import Portal from 'svelte-portal';
	import coryAvatar from '~/assets/images/avatars/avatar-cory.png';
	import lydiaAvatar from '~/assets/images/avatars/avatar-lydia.png';
	import scottAvatar from '~/assets/images/avatars/avatar-scott.jpg';
	import { Button } from '~/components/ui/button';
	import { Input } from '~/components/ui/input';
	import { Label } from '~/components/ui/label';
	import * as Popover from '~/components/ui/popover';
	import { Textarea } from '~/components/ui/textarea';
	import { MAXIMUM_MESSAGE_LENGTH, MINIUM_MESSAGE_LENGTH } from '~/constants';

	export let inputErrors: Record<string, string[] | null | undefined> = {};

	let submitting = false;
	let successfullySubmitted = false;
	let formInputs = {
		name: '',
		email: '',
		message: '',
	};
	let systemError = '';

	const avatars = [
		{
			src: coryAvatar.src,
			alt: 'Cory',
		},
		{
			src: scottAvatar.src,
			alt: 'Scott',
		},
		{
			src: lydiaAvatar.src,
			alt: 'Lydia',
		},
	];

	let form: HTMLFormElement;

	async function handleSubmit(event: SubmitEvent) {
		event.preventDefault();

		submitting = true;
		// Show alert pop-up with greeting from action
		const formData = new FormData(form);
		const { error } = await actions.submitContactMessage(formData);

		if (error) {
			submitting = false;

			// There was some sort of input error and default HTML forms failed
			if (isInputError(error)) {
				const fields: Array<keyof typeof formInputs> = ['email', 'message', 'name'];
				fields.forEach((field) => {
					if (error.fields[field]) {
						inputErrors[field] = error.fields[field];
					}
				});
			}

			// User was rate limited - show error
			if (error?.code === 'TOO_MANY_REQUESTS') {
				systemError = error.message;
			}

			if (error?.code === 'INTERNAL_SERVER_ERROR') {
				systemError = error.message;
			}

			return;
		}

		// SUCCESS
		handleSuccess();
	}

	function handleSuccess() {
		successfullySubmitted = true;
		submitting = false;
		systemError = '';
		clearInputs();
	}
	function clearInputs() {
		// @ts-ignore
		formInputs = Object.fromEntries(Object.keys(formInputs).map((key) => [key, '']));
	}

	function handleOpenChange(open: boolean) {
		if (!open) {
			successfullySubmitted = false;
		}
	}

	onMount(() => {
		// Create portal
		const portal = document.createElement('div');
		portal.id = 'beacon';
		portal.classList.add('absolute', 'z-10');
		// Preppend before HTML body
		document.documentElement.append(portal);

		return () => {
			document.documentElement.removeChild(portal);
		};
	});
</script>

<Portal target="#beacon">
	<div
		class="beacon-wrapper not-content fixed bottom-7 right-7 sm:bottom-10 sm:right-10 z-100 animated zoomIn"
	>
		<Popover.Root onOpenChange={handleOpenChange} portal={null}>
			<Popover.Trigger asChild let:builder>
				<Button
					builders={[builder]}
					class="beacon-button rounded-[20px] sm:rounded-[27px] bg-[#ff6506] hover:bg-[#FF752B] dark:hover:bg-[#E75F0F] cursor-pointer flex items-center justify-center p-0 w-12 h-12 sm:w-16 sm:h-16 shadow-lg hover:scale-110 transition-transform"
				>
					<!-- question mark -->

					<svg
						class="icon-question-mark h-8 w-8 sm:h-10 sm:w-10"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						><path
							fill="currentColor"
							d="M15.312 7.96q0-1.402-.944-2.254t-2.483-.852q-.994 0-1.736.39q-.741.389-1.282 1.2q-.169.229-.433.288q-.265.058-.476-.103q-.177-.133-.215-.348q-.037-.215.084-.41q.723-1.104 1.73-1.641t2.328-.538q2.04 0 3.331 1.183q1.292 1.183 1.292 3.062q0 1.048-.456 1.957q-.456.91-1.423 1.77q-1.175 1.028-1.615 1.727q-.441.7-.464 1.555q-.023.254-.195.423q-.172.17-.42.17t-.42-.176t-.173-.424q0-1.033.493-1.884q.492-.851 1.646-1.866q1.025-.895 1.428-1.632q.403-.738.403-1.597M11.885 21q-.402 0-.701-.299q-.3-.299-.3-.701t.3-.701t.7-.299t.702.299t.299.701t-.3.701t-.7.299"
						/></svg
					>

					<!-- chevron/down -->
					<svg
						class="icon-chevron h-8 w-8 sm:h-10 sm:w-10 rotate-90 hidden"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						><path
							fill="currentColor"
							d="m13.292 12l-4.6-4.6l.708-.708L14.708 12L9.4 17.308l-.708-.708z"
						/></svg
					>
				</Button>
			</Popover.Trigger>
			<Popover.Content
				fitViewport={true}
				class="p-0 w-[375px] border border-border bg-transparent shadow-lg z-[11]"
				sideOffset={16}
			>
				<div class="bg-[#ff6506] text-center rounded-t-md p-4 sm:p-6">
					<div class="flex -space-x-2 overflow-hidden justify-center py-1">
						{#each avatars as data}
							<img
								class="inline-block h-10 w-10 rounded-full ring-2 ring-white"
								src={data.src}
								alt={data.alt}
							/>
						{/each}
					</div>

					<div class="text-white">Email our engineers</div>
					<div class="text-xs text-white opacity-80">
						We'll get back to you as soon as humanly possible
					</div>
				</div>

				<div class="p-4 sm:p-6 bg-[#f9fafa] dark:bg-[#131313] rounded-b-md">
					{#if successfullySubmitted}
						<div class="flex flex-col gap-y-2 text-center items-center">
							<!-- check/tick-circle -->
							<svg
								aria-hidden="true"
								class="h-8 w-8 text-green-500"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
							>
								<path
									d="m8.5 12.512 2.341 2.339A14.985 14.985 0 0 1 15.4 9.915l.101-.069M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
								/>
							</svg>
							<h1 class="text-2xl font-semibold tracking-tight">Message sent!</h1>
							<p class="text-sm text-muted-foreground">
								Thank you for contacting us. We'll get back to you as soon as possible.
							</p>
						</div>
					{:else}
						<form
							id="contact-form"
							bind:this={form}
							method="POST"
							on:submit={handleSubmit}
							action={actions.submitContactMessage}
						>
							<div class="grid w-full items-center gap-3 sm:gap-4">
								{#if systemError.length > 0}
									<div
										class="w-full space-y-2 md:space-y-0 md:flex md:items-center md:justify-between lg:gap-x-3 bg-red-100 dark:bg-red-600/80
		 rounded-md px-[12px] py-[9px] text-sm leading-[21px]"
									>
										<div class="flex items-center gap-2 text-red-700 dark:text-red-200">
											<div class="w-5 h-5">
												<!----><!---->
												<svg
													aria-hidden="true"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
												>
													<path
														d="M12 13V8.938M12 16v-.001m1.236-11.747a3.152 3.152 0 0 0-2.472 0c-2.356 1.003-7.924 9.9-7.76 12.279.063.932.525 1.79 1.265 2.35 1.967 1.492 13.495 1.492 15.462 0a3.227 3.227 0 0 0 1.265-2.35c.164-2.378-5.404-11.276-7.76-12.279Z"
													/>
												</svg>
											</div>
											<span><!---->{systemError}<!----></span>
										</div>
										<!---->
									</div>
								{/if}
								<div class="flex flex-col space-y-1.5">
									<Label for="name" class="text-[var(--sl-color-text)] text-sm text-[.83rem]"
										>Your Name</Label
									>
									<Input
										class="text-base sm:text-sm"
										bind:value={formInputs.name}
										name="name"
										id="name"
										on:input={() => (inputErrors.name = null)}
										required
										data-1p-ignore
									/>
									{#if inputErrors.name}
										<p class="text-destructive text-sm text-[.83rem]">
											{inputErrors.name.join(',')}
										</p>
									{/if}
								</div>
								<div class="flex flex-col space-y-1.5">
									<Label for="email" class="text-[var(--sl-color-text)] text-sm text-[.83rem]"
										>Your Email Address</Label
									>
									<Input
										class="text-base sm:text-sm"
										bind:value={formInputs.email}
										required
										name="email"
										id="email"
										type="email"
										on:input={() => (inputErrors.email = null)}
										data-1p-ignore
									/>
									{#if inputErrors.email}
										<p class="text-destructive text-sm text-[.83rem]">
											{inputErrors.email.join(',')}
										</p>
									{/if}
								</div>
								<div class="flex flex-col space-y-1.5">
									<Label for="message" class="text-[var(--sl-color-text)] text-sm text-[.83rem]"
										>How can we help?</Label
									>
									<Textarea
										class="resize-none h-24 text-base sm:text-sm"
										bind:value={formInputs.message}
										required
										name="message"
										id="message"
										minlength={MINIUM_MESSAGE_LENGTH}
										maxlength={MAXIMUM_MESSAGE_LENGTH}
										data-1p-ignore
									/>
									{#if inputErrors.message}
										<p class="text-destructive text-sm text-[.83rem]">
											{inputErrors.message.join(',')}
										</p>
									{/if}
								</div>
							</div>
							<div class="flex justify-between mt-6">
								<Button disabled={submitting} type="submit" class="w-full  cursor-pointer">
									{#if submitting}
										<svg
											aria-hidden="true"
											class="mr-2 w-4 h-4"
											xmlns="http://www.w3.org/2000/svg"
											width="1em"
											height="1em"
											viewBox="0 0 24 24"
											><path
												fill="currentColor"
												d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
												opacity="0.25"
											/><path
												fill="currentColor"
												d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
												><animateTransform
													attributeName="transform"
													dur="0.75s"
													repeatCount="indefinite"
													type="rotate"
													values="0 12 12;360 12 12"
												/></path
											></svg
										>
										Sending...
									{:else}
										Send Message
									{/if}
								</Button>
							</div>
						</form>
					{/if}
				</div>
			</Popover.Content>
		</Popover.Root>
	</div>
</Portal>

<style>
	:root {
		--animate-duration: 0.2s;
		--animate-delay: 0s;
		--animate-repeat: 1;
	}

	.animated {
		animation-duration: var(--animate-duration);
		animation-fill-mode: both;
	}

	@keyframes zoomIn {
		from {
			opacity: 0;
			transform: scale3d(0.3, 0.3, 0.3);
		}

		50% {
			opacity: 1;
		}
	}

	.zoomIn {
		animation-name: zoomIn;
	}

	@media print, (prefers-reduced-motion: reduce) {
		.animated {
			animation-duration: 1ms !important;
			transition-duration: 1ms !important;
			animation-iteration-count: 1 !important;
		}
	}

	:global(.beacon-wrapper:has(div[data-popover-content]:not([inert])) .beacon-button) {
		transform: scale(110%);
	}

	.beacon-wrapper:has(div[data-popover-content]:not([inert])) .beacon-button .icon-question-mark {
		display: none;
	}

	.beacon-wrapper:has(div[data-popover-content]:not([inert])) .beacon-button .icon-chevron {
		display: block !important;
	}

	:global(
			:root[data-theme='light']
				.beacon-wrapper:has(div[data-popover-content]:not([inert]))
				.beacon-button
		) {
		background-color: #ff752b;
	}

	:global(
			:root[data-theme='dark']
				.beacon-wrapper:has(div[data-popover-content]:not([inert]))
				.beacon-button
		) {
		background-color: #e75f0f;
	}
</style>
